<template>
  <div class="w-full text-gray-400 font-sans py-4">
    <slot name="header">
      <div class="relative border-b border-gray-50" v-if="!loading">
        <slot name="title">
          <span class="text-lg font-bold capitalize px-4">
            {{ `Weather for ${location.name}, ${location.region}` }}
          </span>
        </slot>
      </div>
    </slot>

    <div class="h-64 flex md:flex-row items-center p-4">
      <div class="w-full flex items-center justify-center" v-if="loading">
        <slot name="loading">
          <i class="fas fa-spinner fa-spin fa-fw fa-3x"></i>
          <span class="text-lg font-bold capitalize px-4">
            Loading...
          </span>
        </slot>
      </div>

      <template v-else>
        <div class="w-1/3 h-full flex flex-col justify-center items-center content-center">
          <div class="flex justify-center items-center content-center">
            <img :src="localIcon(current.condition.icon)" class="object-contain h-20 w-20" />
            <div class="py-2 text-6xl font-bold leading-6">
              {{ Math.round(current.temp_f) }}&deg;
            </div>
          </div>
          <div class="flex flex-col justify-center items-center content-center">
            <div class="text-lg font-bold capitalize px-4 text-center">
              {{ current.condition.text }}
            </div>
            <div class="text-lg">
              Wind: {{ Math.round(current.wind_mph) }} mph ({{ windBearing }})
            </div>
          </div>
        </div>

        <div class="flex items-stretch flex-col md:flex-row justify-around w-full">
          <div class="flex flex-col items-center justify-evenly" :key="daily.time" v-for="daily in forecast.forecastday">
            <span class="hidden lg:block">
              {{ dayName(daily.date, 'dddd')  }}
            </span>
            <span class="block lg:hidden">
              {{ dayName(daily.date, 'ddd') }}
            </span>
            <img :src="localIcon(daily.day.condition.icon)" class="h-10 w-10 object-contain" />
            <span class="text-sm py-1 hidden md:block break-all">
              {{ daily.day.condition.text }}
            </span>
            <div class="flex flex-col">
              <span class="text-xs tooltip tooltip-bottom" data-tooltip="Sunrise">
                <i class="fas fa-sunrise fa-lg fa-fw mx-2"></i>
                {{ daily.astro.sunrise }}
              </span>
              <span class="text-xs tooltip tooltip-bottom" data-tooltip="Sunset">
                <i class="fas fa-sunset fa-lg fa-fw mx-2"></i>
                {{ daily.astro.sunset }}
              </span>
            </div>
            <div class="py-2 w-8 px-2" style="height: calc(100% - 100px);">
              <div class="text-center">
                <span>{{ Math.round(daily.day.maxtemp_f) }}&deg;</span>
              </div>
              <div
                :style="{
                  borderRadius: '10px',
                  background: '#ccc',
                  height: `${daily.day.maxtemp_f}%`,
                }"
              >
                &nbsp;
              </div>
              <div class="text-center">
                <span>{{ Math.round(daily.day.mintemp_f) }}&deg;</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {

    name: "weather",

    props: {
      // The latitude of a location (in decimal degrees).
      // Positive is north, negative is south.
      latitude: {
        type: String,
      },

      // The longitude of a location (in decimal degrees).
      // Positive is east, negative is west.
      longitude: {
        type: String,
      },
    },

    data() {
      return {
        apiKey: process.env.MIX_WEATHER_API_KEY,
        loading: true,
        location: null,
        current: null,
        forecast: null,
        alert: null,
        errorMessage: null
      };
    },

    computed: {
      windBearing() {
        const t = Math.round(this.current.wind_degree / 45);
        return ["N", "NE", "E", "SE", "S", "SW", "W", "NW", "N"][t];
      }
    },

    methods: {
      dayName(date, size) {
        return moment(date).format(size);
      },
      localIcon(icon) {
        return icon.replace("//cdn.weatherapi.com/", "/img/");
      },
    },

    created() {
      fetch(`https://api.weatherapi.com/v1/forecast.json?key=${this.apiKey}` +
            `&q=${this.latitude},${this.longitude}` +
            `&days=7`)
        .then(async response => {
          const data = await response.json();

          // check for error response
          if(!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }

          this.location = data.location;
          this.current = data.current;
          this.forecast = data.forecast;
          this.alert = data.alert;
          this.loading = false;
          console.log(this.current.condition.icon);
        })
        .catch(error => {
          this.errorMessage = error;
          console.error("There was an error!", error);
        });
    }

  };
</script>
