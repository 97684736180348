/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Vue = require('vue');

var moment = require('moment');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
import Vue from 'vue';
import { Datetime } from 'vue-datetime';

// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css';

Vue.component('datetime', Datetime);
Vue.component('account-dropdown', require('./components/AccountDropdown.vue').default);
Vue.component('weather-widget', require('./components/Weather.vue').default);
Vue.component('toggle-switch', require('./components/ToggleSwitch.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
