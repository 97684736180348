<template>
	<div class="ml-3 relative">
		<div>
			<button @click="isOpen = !isOpen" class="relative max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:bg-gray-100 px-2 lg:rounded-md lg:hover:bg-gray-100 z-10" id="user-menu" aria-label="User menu" aria-haspopup="true">
				<slot name="avatar"></slot>
				<p class="ml-3 text-gray-700 text-sm leading-5 font-medium">
					<slot name="username"></slot>
				</p>
				<svg class="flex-shrink-0 ml-1 h-5 w-5 text-gray-400 block" viewBox="0 0 20 20" fill="currentColor">
					<path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
				</svg>
			</button>
		</div>
		<button v-if="isOpen" @click="isOpen = false" class="fixed inset-0 h-full w-full bg-blue-700 opacity-50 cursor-default" tabindex="-1"></button>
		<!--
			Profile dropdown panel, show/hide based on dropdown state.

			Entering: "transition ease-out duration-100"
			From: "transform opacity-0 scale-95"
			To: "transform opacity-100 scale-100"
			Leaving: "transition ease-in duration-75"
			From: "transform opacity-100 scale-100"
			To: "transform opacity-0 scale-95"
		-->
		<transition
			enter-active-class="transition ease-out duration-100"
			enter-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
			<div v-if="isOpen" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
				<div class="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
					<slot name="links"></slot>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isOpen: false
			}
		},
		created() {
			const handleEscape = (e) => {
				if(e.key === 'Esc' || e.key === 'Escape') {
					this.isOpen = false
				}
			}

			document.addEventListener('keydown', handleEscape)

			this.$once('hook:beforeDestroy', () => {
				document.removeEventListener('keydown', handleEscape)
			})
		}
	}
</script>
